.Logo {
    display: flex;
    justify-content: space-between;
    gap: 9px;
    width: fit-content;

    cursor: pointer;

    &__icon {
        height: 21px;
        width: 16px;
    }

    &__caption {
        color: white;
        font-weight: 500;
    }
}