@import "../../style/breakpoints.scss";

.TermsAndPrivicyPages {
    display: flex;
    flex-direction: column;
    gap: 40px;

    max-width: 856px !important;
    width: 100%;
    padding-top: calc(77px + 80px);
    padding-bottom: 80px;

    h2 {
        text-align: center;
    }

    &__title {
        margin-bottom: 24px;

        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }

    &__subTitleContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: left;
            color: rgba(168, 170, 183, 1);
            text-align: left;
        }
    }

    &__subtitle {
        color: rgba(168, 170, 183, 1);
    }

    @media (max-width: $lg-min) {
        padding-top: 80px;
    }
}