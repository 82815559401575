.Container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    margin-top: 24px;
}

.TextDetectionResult {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__flexWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        width: 100%;
    }

    &__titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_img {
            
            width: 50px;
            background-color: transparent;
        }

        small {
            color: white;
            opacity: 0;

            animation-name: fadeIn;
            animation-duration: 0.8s;
            animation-fill-mode: forwards;
        }
    }

    &__indicatorContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        flex-grow: 5;
    }

    &__indicator {
        position: relative;

        background-color: rgba(248, 248, 248, 1);
        height: 6px;
        border-radius: 10px;

        &_greenBar {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;

            background-color: rgba(0, 172, 59, 1);
            border-radius: 10px;

            transition: ease-in-out all 0.6s;
        }
    }
    @media screen and (max-width: 1044px) {
        margin-top: 40px;
    }

    @media screen and (max-width: 768px) {
        &__flexWrapper {
            grid-template-columns: 1fr;
        }
    }
}

.ResultIconContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    animation-name: fadeIn;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;

    &__indicator {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        width: 24px;
        height: 24px;

        // background-color: rgba(0, 172, 59, 1);
    }

    &__indicator_green {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        width: 24px;
        height: 24px;

        background-color: rgba(0, 172, 59, 1);
    }

    small {
        color: rgba(248, 248, 248, 1);
    }
    @media screen and (max-width: 1044px) {
        top: 0!important;
    }
}

@keyframes rotateCounterClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.LoaderAnimation {
    animation: rotateCounterClockwise 1s linear infinite;
}
