@import "../../../style/breakpoints.scss";

.ShadowGPTReview {
    // padding-top: 120px;

    &__topContent {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__marginWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        margin-bottom: 25px;
        text-align: center;
    }

    &__caption {
        margin-bottom: 80px;
    }

    &__blockIcon {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    &__background {
        background: linear-gradient(178.3deg, #000000 1.49%, #0E0E0E 48.11%, #000000 98.62%);
    }

    &__bottomContent {
        display: flex;
        justify-content: left;
        gap: 174px;
        padding-left: 140px;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__botttomText {
        display: flex;
        flex-direction: column;
        max-width: 526px;
        width: 100%;

        h2 {
            margin-bottom: 38px;
        }

        p {
            margin-bottom: 40px;
            text-align: left;
        }

        &_button {
            margin-right: auto;
        }
    }

    &__buttonsContainer {
        max-height: 300px;
        width: 400px;
    }

    &__grayButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        margin-bottom: 10px;
        height: fit-content;
        width: fit-content;
        padding: 16px 24px 16px 24px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 8px;
        background-color: rgba(31, 31, 37, 1);
        transition: all ease 0.3s;
        cursor: grab;

        &_text {
            display: block;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.02em;
            text-align: left;
            color: rgba(168, 170, 183, 1);
            transition: all ease-in 0.3s;
        }
    }

    &__darkButton {
        display: flex;
        justify-content: center;
        gap: 9px;
        padding: 16px 24px 16px 24px;
        border-radius: 8px;
        cursor: grab;

        background-color: rgba(7, 7, 7, 1);
        box-shadow: 0px 0px 15px 0px rgba(86, 86, 86, 0.1);

        &_text {
            font-size: 40px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0.02em;
            text-align: left;
        }
    }

    &__btnIcon {
        width: 100%;
        height: 100%;
        max-height: 24px;
        max-width: 24px;
        transition: 0.3s ease all;

        &_active {
            max-height: 40px;
            max-width: 40px;
        }
    }

    @media (max-width: $lg-min) {
        
        &__topContent {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__bottomContent {
            flex-direction: column-reverse;
            align-items: center;
            padding-left: 0px;
            padding-top: 40px;
            padding-bottom: 40px;
            gap: 40px;
        }

        &__buttonsContainer {
            max-width: 280px;
            width: 100%;
        }

        &__botttomText {
            text-align: center;

            p {
                text-align: center;
            }

            &_button {
                margin-left: auto;
            }
        }

        &__buttonsContainer {
            width: 100%;
            max-width: 400px;
        }
    }
}

.Content {

    &__icon {
        margin-bottom: 24px;
    }

    &__button {
        padding: 8px 24px 8px 24px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
    }
}

.thirdBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    
    &__caption {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0em;
        color: rgba(248, 248, 248, 1);
    }
}