.ButtonGoogle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 100%;
    padding: 12px 0px 12px 0px;
    background-color: rgba(31, 31, 37, 1);
    border-radius: 4px;
    cursor: pointer;

    small {
        color: rgba(248, 248, 248, 1);
    }

}