.HeaderPcDropdown {
    padding: 24px;
    background: #070707;
    border-radius: 8px;
    width: 190px;
    position: absolute;
    top: 64px;
    right: 20px;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    transform: translateY(-10px) translateX(5px);
    &__show {
        user-select: none;
        visibility: visible;
        opacity: 1;
        transform: translateY(0) translateX(0);
    }

    h3 {
        color: #f8f8f8;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
    }
    small {
        color: #f8f8f8;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
    }
    &_links {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
        a,
        div {
            cursor: pointer;
            text-decoration: none;
            color: #f8f8f8;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            transition: 0.15s all ease;
            &:hover,
            &:active,
            &:focus {
                color: #fd3806;
            }
        }
        a svg,
        div svg {
            margin-right: 8px;
        }
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.Progress_block {
    &_bar {
        background: #f8f8f8;
        height: 6px;
        width: 100%;
        border-radius: 10px;
        position: relative;
        margin: 4px 0;
        div {
            background: #393943;
            border-radius: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
