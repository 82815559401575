@import "../../../../../../style/breakpoints.scss";

.InfoBadge {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 388px;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
    background-color: rgba(255, 255, 255, 0.05);

    h5 {
        margin-bottom: 34px;
        padding-bottom: 23px;
        border-bottom: rgba(0, 0, 0, 1) 1px solid;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }

    &__flexMainWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        margin-bottom: 24px;
    }

    &__flexWrapper {
        display: flex;
        justify-content: flex-start;
        gap: 16px;


        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: left;
            color: rgba(248, 248, 248, 1);
        }
    }

    &__pContainer {
        width: 100%;
        max-width: 110px;
    }

    &__grayCaption {
        color: rgba(168, 170, 183, 1) !important;
        &::first-letter { 
            text-transform: uppercase;
        }
    }

    &__changePlanBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        width: fit-content;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
    }

    @media (max-width: $lg-min) {
        width: 100%;
        max-width: 388px;

        h5 {
            font-size: 16px;
            margin-bottom: 23px;
        }
    }
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}