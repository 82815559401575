@import '../../../../../../style/breakpoints.scss';

.Input {
    width: 100%;
    max-height: 166px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 880px;
        height: 52px;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-50%);
        left: 50%;
    }

    &__textarea {
        display: block;
        padding: 16px;
        padding-right: 92px;
        height: 52px;
        width: 100%;
        max-height: 166px !important;
        outline: none;
        border: none;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 1);
        border-radius: 12px;
        background: #181818;
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        resize: none;
        color: rgba(168, 170, 183, 1);
        &::placeholder {
            white-space: nowrap;
        }
        @media screen and (max-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__icon {
        z-index: 11;
        position: absolute;
        bottom: 16px;
        right: 19px;
        cursor: pointer;
    }

    @media (max-width: $md) {
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        height: 52px;
        &__container {
            padding-left: 16px;
            padding-right: 16px;
            position: absolute;
            bottom: 20px;
            left: 0;
            transform: translateX(0);
        left: 0;
        }
    }
}
