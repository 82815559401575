.Logo {
    display: flex;
    justify-content: space-between;
    gap: 9px;
    width: fit-content;

    cursor: pointer;

    &__icon {
        height: 21px;
        width: 16px;
    }

    &__caption {
        color: white;
        font-weight: 500;
    }
    &__subtitle {
        font-size: 0.7em;
        opacity: 0.7;
        font-weight: normal;
      }
}