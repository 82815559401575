*{
    scrollbar-width: 100px;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(31, 31, 37, 1);
  width: 100px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(57, 57, 67, 1);
  border-radius: 10px;
}
