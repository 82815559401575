.ButtonTiny {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    width: fit-content;

    border-radius: 4px;
    background-color: rgba(19, 19, 19, 1);
    padding: 4px 8px 4px 8px;

    * {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }
}