.VerificationInput {
    display: flex;
    justify-content: center;
    gap: 5px;

    margin-bottom: 16px;
    height: 80px;
    *::selection {
        background: transparent;
    }

    &__input {
        
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(168, 170, 183, 1);
        outline: none;
        width: 60px;

        font-family: "Inter";
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(248, 248, 248, 1);
    }
}