@import "../../../style/breakpoints.scss";

.DodgeAiCheckers {
    padding-top: 120px;
    padding-bottom: 120px;
    
    &__textContainer {
        max-width: 856px;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        margin-bottom: 19px;
        text-align: center;
        color: #F8F8F8;
    }

    &__subTitle {
        margin-bottom: 40px;

        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #A8AAB7;
    }

    &__margin {
        padding-top: 120px;
        padding-bottom: 40px;
    }

    &__blockIcons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        max-width: 416px;
    }

    &__GhostWithArrows {
        display: block;
    }

    &__GhostWithArrowsMobile {
        display: none;
    }

    &__blockIcon {
        min-width: 416px;
        height: 182px;
        max-width: 416px;
    }

    &__arrowIcon {
        min-width: 246px;
    }

    &__companyIcon {
        fill: rgba(248, 248, 248, 1);
        cursor: pointer;
        transition: 0.3s all ease;

        &:hover {
            fill: rgba(255, 74, 30, 1);
        }
    }

    @media(max-width: $lg-min) {
        padding-top: 40px;
        padding-bottom: 40px;

        &__blockIcons {
            flex-direction: column;
        }

        &__GhostWithArrows {
            display: none;
        }
    
        &__GhostWithArrowsMobile {
            display:initial;
        }

        &__margin {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__blockIcon {
            min-width: initial;
            height: initial;
            max-width: initial;
        }
    }
}

.iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 45px;

    @media(max-width: $lg-min) {
        flex-wrap: wrap;
    }
}