.LanguageInfo {
    position: relative;
    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    &__language {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(248, 248, 248, 1);
        text-transform: uppercase;
    }
}

.LanguageInfoBody {
    padding: 24px;
    background: #070707;
    border-radius: 8px;
    width: 110px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 15px;
    &__show {
        user-select: none;
        visibility: visible;
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 10px 0;
        gap: 8px;
        p { 
            margin: 0;
        }
    }
    &__wide {
    }
}
