.Action {
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    width: 160px;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(31, 31, 37, 1);
    cursor: pointer;
    transition: 0.3s ease background-color;


    &__caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: rgba(248, 248, 248, 1);
    }
}

.Action:hover {
    background-color: #FF4A1E;
}