@import "../../../style/breakpoints.scss";

.FAQBlock {
    position: relative;

    display: flex;
    justify-content: center;

    padding-top: 120px;
    padding-bottom: 121px;

    &__vector1 {
        position: absolute;
        z-index: -1;

        top: -500px;
        left: -25%;

        border-radius: 50%;
        width: 968px;
        height: 968px;

        background: linear-gradient(90deg, rgba(0,0,0,1) 36%, rgba(0,0,0,0) 70%);
        opacity: 0.8;
    }

    &__squareElement {
        position: absolute;
        top: -40%;
        right: 0px;
        display: block;

        width: 768.32px;
        height: 768.32px;

        transform: rotate(-105deg);

        background: radial-gradient(40.2% 31.41% at 53.15% 54.03%, #3D3D3D 0%, #181818 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border: 1px solid;
        opacity: 0.09;

        border-image-source: linear-gradient(207.12deg, #000000 8.94%, #161517 57.31%);
        
        
        // border: 1px solid red;
    }

    &__positionWrapper {
        z-index: 2;
        position: relative;
    }

    &__wrapper {
        max-width: 1076px;
        width: 100%;
    }

    h2 {
        margin-bottom: 24px;
        text-align: center;
    }

    &__subTitle {
        width: 100%;
        max-width: 416px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    @media (max-width: $lg-min) {
        padding-top: 40px;
        padding-bottom: 41px;


        &__vector1 {
            display: none;
        }
    }
}


.FAQSection {
    position: relative;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    // gap: 16px;

    max-width: 1076px;
    // padding: 40px;
    background: rgba(27, 27, 27, 0.4);
    border-radius: 12px;
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        padding: 40px;
        border-radius: 12px;

        width: 100%;
        height: 100%;
    }

    &__blurMask {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(3px);
        border-radius: 12px;
    }

    &__item {
        position: relative;
        z-index: 2;

        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
    }


    &__titemTitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
        cursor: pointer;
    }

    &__titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__itemDescription {
        text-align: left;
        overflow: hidden;
        transition: max-height 0.3s ease;
        height: 100%;
        max-height: 0;
        padding-top: 22px;

        &_active {
            max-height: 300px;
        }
    }

    &__arrowIcon {
        width: 23px;
        height: 14px;
        transition: transform 0.3s ease-out;
        transform: rotate(0deg);
        cursor: pointer;

        &_active {
            transform: rotate(-180deg);
        }
    }

    @media (max-width: $lg-min) {
        gap: 5px;
        padding: 16px;
        background-color: rgba(7, 7, 7, 1);

        &__titleContainer {
            gap: 6px;
        }

        &__arrowIcon {
            width: 20px;
            height: 8px;
        }
    }
    @media screen and (max-width: 768px) {
        &__wrapper { 
            padding: 40px 0;
        }
    }
}