.ConfirmationPopover {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    p {
        color: white;
    }

    &__title {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        max-width: 400px;
        width: 100%;

        padding: 24px;
        background-color: rgba(7, 7, 7, 1);
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 24px;
    }

    &__button {
        padding: 16px, 32px, 16px, 32px;
    }

    @media screen and (max-width: 768px) { 
        &__container { 
            width: 70vw;
        }
    }
}