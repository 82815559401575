@import '../../../../style/mixins.scss';
@import '../../../../style/breakpoints.scss';

.History {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    width: 100%;
    height: 100%;

    &__title {
        height: auto;
        font-size: 12px;
        position: absolute;
        font-weight: 400;
        color: #fff;
        z-index: 1;
        top: 30px;
        left: 24px;
        span {
            height: 16px;
            padding: 0 4px;
            margin-left: 8px;
            background: rgba(253, 55, 7, 0.2);
            color: #FD3707;
            border-radius: 4px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        background-image: url('../../assets/background.svg'), linear-gradient(110deg, #000000 0%, #0A0A0A 43%, #120E0D 100%);
        background-repeat: no-repeat, no-repeat;
        background-position: center, center;
        background-size: cover, cover;
        background-blend-mode: overlay, normal;
    }

    &__backgroundMask {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 23%,
            rgba(0, 0, 0, 0.8855917366946778) 32%,
            rgba(0, 0, 0, 0) 46%
        );
    }

    &__vector1 {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__vector2 {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.Content {
    z-index: 2;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
    max-width: 800px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-right: 16px;

        overflow-y: scroll;
    }

    &__textWrapper {
        display: flex;
        margin-bottom: 8px;
    }

    &__indicatorWrapper {
        display: flex;
        align-items: center;
        @include divider;
    }

    &__indicator {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        border-radius: 50%;
        background: rgba(0, 239, 215, 1);

        &_red {
            background-color: rgba(253, 55, 7, 1);
        }
    }

    &__time {
        @include divider;
    }

    &__nickContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
        img { 
            width: 24px;
            height: 24px;
        }
    }

    &__nick {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: -0.01em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }

    &__copyItem {
        margin: 0;
    }

    &__copyContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__wordsCount {
        color: rgba(168, 170, 183, 1);
    }

    &__text {
        padding: 24px;
        margin-bottom: 8px;

        background-color: #181818;
        box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
        border-radius: 8px;
        transition: 0.4s ease all;
        overflow: hidden;

        &_main {
            margin-bottom: 24px;
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: left;
            color: rgba(248, 248, 248, 1);
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        &_animated {
            max-height: 100% !important;
        }
    }

    &__margin0 {
        display: flex;
        justify-self: flex-start;
        align-items: center;

        margin-bottom: 0;
    }

    &__badgeContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    @media (max-width: $lg) {
        &__wrapper {
            margin-top: 65px;
        }
    }

    @media (max-width: $lg-min) {
        &__wrapper {
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.ChatMessage {
    &__item {
        margin-bottom: 24px;
        padding: 0 24px;
    }
    &__item_bg {
        background: rgba(29, 29, 29, 0.96);
        border-radius: 20px;
        border: 1px solid rgb(19, 19, 19);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        padding: 24px;
    }
}

.fadeInOut {
    opacity: 0;
    animation: fadeInOut 2s forwards alternate;
}

.hidden {
    opacity: 1;
    animation: fadeIn 2s forwards alternate;
}

.fadeInOut1 {
    opacity: 0;
    animation: fadeInOut1 0.5s forwards alternate;
}

.hidden2 {
    opacity: 1;
    animation: fadeIn2 0.5s forwards alternate;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
