@import "../../../style/breakpoints.scss";

.ShadowsReference {
    position: relative;
    overflow: hidden;

    &__content {
        z-index: 4;
        position: relative;
        display: flex;
        gap: 135px;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__radialGradient1 {
        z-index: 3;
        position: absolute;
        top: -50%;
        left: -350px;
        width: 1000px;
        height: 1000px;
        border-radius: 50%;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,1) 7%, rgba(0,0,0,0.7063200280112045) 45%, rgba(0,0,0,0) 62%);
    }

    &__radialGradient2 {
        z-index: 3;
        position: absolute;
        bottom: -50%;
        right: -350px;
        width: 1000px;
        height: 1200px;
        border-radius: 50%;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,1) 7%, rgba(0,0,0,0.7063200280112045) 45%, rgba(0,0,0,0) 62%);
    }

    &__noiseBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background-image: url('./assets/background.svg'), linear-gradient(140deg, #000000 0%, #161616 49%, #000000 100%);
        background-repeat: no-repeat, no-repeat;
        background-size: cover, 100% 100%;
        background-position: center, center;
        background-blend-mode: overlay, normal;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background-image: url('./assets/background.svg'), linear-gradient(140deg, #000000 0%, #161616 49%, #000000 100%);
        background-repeat: no-repeat, no-repeat;
        background-size: cover, 100% 100%;
        background-position: center, center;
        background-blend-mode: overlay, normal;
    }

    &__leftPart {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 526px;
        width: 100%;
    }

    &__title {
        margin-bottom: 39px;
    }

    &__subTitle {
        margin-bottom: 35px;
        text-align: left;
        color: white;
    }

    &__buttonContainer {
       display: flex;
    }

    @media (max-width: $lg-min) {

        &__content {
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 40px;
            gap: 40px;
        }

        h2 {
            margin-bottom: 21px;
            text-align: center;
        }

        p {
            text-align: center;
        }

        &__buttonContainer {
            display: none;
         }
    }
}