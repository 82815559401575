.Input {

    &__label {
        margin-bottom: 4px;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(168, 170, 183, 1);
    }

    &__input {
        width: 100%;

        padding: 12px;
        outline: none;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid rgba(168, 170, 183, 1);
        
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        &::placeholder {
            opacity: 0.5;
        }
    }

    &__input::placeholder {
        color: white;
    }
    &__input:disabled { 
        user-select: none;
        pointer-events: none;
    }
}