@import "../../../../../style/breakpoints.scss";

.StepDescription {
    display: flex;
    justify-content: center;
    gap: 134px;

    max-width: 1076px;
    padding-top: 24px;
    padding-left: 110px;
    padding-right: 123px;
    padding-bottom: 24px;

    box-shadow: 0px 0px 15px 0px #FE471B1A;
    background-color: #070707;
    border-radius: 20px;

    &__textContainer {
        max-width: 416px;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: fit-content;
        padding: 24px;
        border: 3px solid rgba(19, 19, 19, 1);
        border-radius: 20px;
    }

    &_reversed {
        flex-direction: row-reverse;
    }

    @media (max-width: $lg-min) {
        align-items: center;
        flex-direction: column-reverse;
        padding: 24px 12px 24px 12px;
        gap: 40px;

        &_reversed {
            flex-direction: column-reverse;
        }
    }
}

.step {
    display: inline-block;
    margin-bottom: 28px;
    padding: 4px;
    background: rgba(253, 55, 7, 0.2);
    border-radius: 4px;

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    color: #FD3707;
}

.title {
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0em;
    color: #F8F8F8;
    text-align: left;
}

.description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #F8F8F8;
    text-align: left;
}