@import "../../../../style/breakpoints.scss";

.SendEmailBlock {
    position: relative;

    &__wrapper {
        z-index: 2;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    
        padding-top: 120px;
        padding-bottom: 50px;
    }

    &__backgroundNoise {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        background-image: 
            url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='matrix' values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E"),
            linear-gradient(to bottom, #0a0a0a, #000000);
        background-blend-mode: soft-light, normal;
        background-repeat: repeat, no-repeat;
        background-size: 200px 200px, cover;
        opacity: 0.4;
    }

    h2 {
        margin-bottom: 16px;
        text-align: center;
    }

    &__subtitle {
        width: 100%;
        p { 
            max-width: 416px;
            margin: 0 auto;
        }
    }

    &__mailBlock {
        display: flex;
        align-items: center;
        gap: 48px;

        width: fit-content;
        padding: 23px 24px 23px 24px;
        border-radius: 12px;
        background-color: rgba(19, 19, 19, 1);
    }

    &__avatars {
        width: 80px;
        height: 32px;
    }

    @media (max-width: $lg-min) {

        &__wrapper {
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }
}