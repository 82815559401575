.CopyElement {
    cursor: pointer;
    
    &__caption {
        margin-right: 7px;
    }

    &__title {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: rgba(168, 170, 183, 1);
        transition: 0.3s ease color;

        &:hover {
            color: rgba(253, 55, 7, 1);
        }
    }
}