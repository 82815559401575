@import "../../style/breakpoints.scss";

.Auth {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    p {
        color: rgba(248, 248, 248, 1);
    }

    &__backgroundElement {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background-image: url('./assets/svg/Auth.svg'), linear-gradient(110deg, #120e0d 0%, #0B0706 49%, #0A0A0A 100%);
        background-repeat: no-repeat, no-repeat;
        background-size: cover, 100% 100%;
        background-position: center, center;
        background-blend-mode: overlay, normal;
    }

    &__vector1 {
        position: absolute;
        top: 101px;
        right: -20px;
    }

    &__vector2 {
        position: absolute;
        top: 0;
        left: 188px;
    }

    &__vector3 {
        position: absolute;
        bottom: 0px;
        left: 0px;
    }

    &__content {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        gap: 40px;
    }

    &__container {
        padding: 24px;
        border-radius: 20px;
        background-color: rgba(7, 7, 7, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 1);
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 372px;
    }

    &__textMargin {
        text-align: center;
        margin-bottom: 16px;
    }

    &__input {
        padding: 12px;
        margin-bottom: 8px;
        outline: none;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid rgba(168, 170, 183, 1);
        
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(168, 170, 183, 1);
    }

    &__button {
        display: flex;
        justify-content: center;
        padding: 12px 24px 12px 24px;
        margin-bottom: 16px;
    }

    &__dividerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        width: 100%;
        margin-bottom: 16px;
        
        small {
            color: rgba(168, 170, 183, 1);
        }

        div {
            width: 61px;
            height: 1px;
            background-color: rgba(168, 170, 183, 1);
            opacity: 0.2;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    &__googleButton {
        margin-bottom: 16px;
    }

    &__bottomCaption {
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(168, 170, 183, 1);

        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.AuthVerification {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;
    height: 80px;

    // background-color: transparent;

    &__character {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 60px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(168, 170, 183, 1);
        outline: none;

        &_filled {
            font-family: "Inter";
            font-size: 32px;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: 0.02em;
            text-align: center;
            color: rgba(248, 248, 248, 1);
        }
    }
}

.AuthFooter {
    display: none;

    @media (max-width: $lg-min) {
        display: block;
    }
}