@import "../../../style/breakpoints.scss";

.PricingBlock {
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: $lg-min) {
        padding-top: 80px;
        padding-bottom: 40px;
    }
}

.Pricing__guarantee {
    text-align: center;
    font-size: 0.9rem;
    color: #e7e7e7;
    margin-bottom: 2rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Pricing {

    &__title {
        margin-bottom: 23px;
        text-align: center;
    }
    
    &__plan {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    &__item {
        cursor: pointer;
    }

    &__red {
        color: #FF4A1E;
    }

    &__white {
        color: rgba(248, 248, 248, 1);
    }

    &__delimiter {
        margin: 0 5px;
    }

    &__discount {
        margin-left: 5px;
        background-color: rgba(253, 137, 7, 1);
        border-radius: 8px;
        padding: 1px 4px 1px 4px;

        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(7, 7, 7, 1);
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 300px);
        justify-content: center;
        gap: 24px;
        width: 100%;
    }

    @media (max-width: $lg-min) {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.PricingItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    min-height: 400px;
    max-width: 302px;// mb temp !!
    padding: 32px 24px 32px 24px;

    border-radius: 12px;
    background-color: rgba(7, 7, 7, 1);
    box-shadow: 0px 0px 50px 0px rgba(68, 18, 5, 1);
    

    small{
        color: rgba(248, 248, 248, 1);
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;  
        text-transform: uppercase;
    }

    &__checkCaptionContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 19px;
        p {

        }
    }

    &__checkIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #A8AAB7;
    }

    &__buttonWrapper {
        border-radius: 8px;
        padding: 16px 32px 16px 32px;
    }

    &__button {
        width: 100%;

        &_text {
            width: 100%;

        }
    }

    &__discountWrapper {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__discount {
        display: flex;
        align-items: center;
        gap: 9px;
        padding: 4px 8px 4px 8px;

        border-radius: 8px;
        background-color: rgba(253, 137, 7, 1);

        &_text {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(7, 7, 7, 1);
        }
    }
}