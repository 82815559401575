@import '../../../style/breakpoints.scss';

.ChooseUsSection {
    padding-top: 120px;
    padding-bottom: 150px;
    position: relative;
    z-index: 1000;
    overflow: hidden;

    &__positionWrapper {
        position: relative;
    }

    &__titleContainer {
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__arrowContainer {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid rgba(248, 248, 248, 1);
        cursor: pointer;

        // &_available {
        //     background-color: rgba(248, 248, 248, 1);
        // }

        &__right {
            transform: rotate(180deg);
        }
    }

    h2 {
        margin-bottom: 40px;
    }

    &__swiperContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    &__swiperMobile {
        display: none;
    }

    &__vector1 {
        position: absolute;
        z-index: 1;

        top: -200%;
        left: -7%;
        width: 968px;
        height: 968px;
        background-image: url('./assets/backgroundVector1.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(30deg);
        background-color: radial-gradient(
            61.64% 61.64% at 43.46% 103.9%,
            #5f5f5f 0%,
            #161616 100%
        );
        border-image-source: linear-gradient(
            247.55deg,
            #000000 13.75%,
            #161517 48.84%
        );

        // border: 1px solid red;
    }

    &__maskVectorEl {
        position: absolute;
        top: -200%;
        left: -75%;
        z-index: 1;

        width: 1000px;
        height: 1000px;

        // background: rgb(0,0,0);
        // background: rgb(0,0,0);
        background: rgb(0, 0, 0);

        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0.9724264705882353) 30%,
            rgba(0, 0, 0, 0.17690826330532217) 67%,
            rgba(0, 0, 0, 0) 72%
        );
    }

    @media (max-width: $lg-min) {
        padding-top: 40px;
        padding-bottom: 40px;

        h2 {
            text-align: center;
        }

        &__swiperContainer {
            z-index: 100;
            position: relative;

            flex-direction: column;
            align-items: center;
            gap: 24px;
        }

        &__vector1 {
            display: none;
        }

        &__swiper {
            display: none;
        }

        &__swiperMobile {
            display: flex;
        }

        &__titleContainer {
            display: flex;
            justify-content: center;
        }

        &__arrowContainer {
            display: none;
        }
    }
}
