@import './style/fonts.scss';
@import './style/Layout.scss';
@import './style/Scrollbar.scss';
@import './style/breakpoints.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
body {
    font-family: 'Inter';
    margin: 0;
    background-color: #070707;
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
    .Toastify__toast-body {
        div {
            font-size: 13px;
        }
    }
    .Toastify__toast {
        min-height: 32px !important;
    }
    .Toastify__toast-container {
        // width: 80vw!important;
        // left: 50%!important;
        top: 62px!important;
        transform: translateX(0);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.PhoneInput {
    padding: 12px;
    border: 1px solid rgba(168, 170, 183, 1);
    margin-bottom: 10px;
}

.PhoneInputInput {
    outline: none;
    background-color: transparent;
    border-radius: 4px;
    margin-left: 6px;
    border: none;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(168, 170, 183, 1);
}

.S9gUrf-YoZ4jf {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhoneInputInternationalIconPhone {
}
.PhoneInputInternationalIconGlobe {
    stroke: rgb(0, 234, 255);
    opacity: 1;
}
.PhoneInputCountrySelect
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: #03b2cb;
}

textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
}

h1 {
    font-family: 'Inter';
    font-size: 54px;
    font-weight: 500;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

h2 {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: white;
}

p {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #a8aab7;
}

small {
    display: block;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(248, 248, 248, 1);
}

.progress {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: rgba(248, 248, 248, 1);
    border-radius: 10px;

    &_bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 10px;
        background: #393943;
    }
}

.PhoneInputCountrySelect {
    background: #000;
    option {
        color: #fff;
    }
}

@media (max-width: $lg-min) {
    h1 {
        font-family: 'Inter';
        font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0.02em;
    }
}
