.Notification {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 16px;
    overflow: hidden;

    max-width: 406px;
    width: 100%;
    // height: 90px;
    padding: 12px 16px 12px 16px;
    background-color: rgba(19, 19, 19, 1);

    border-radius: 8px;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.14);

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 32px;
        height: 32px;
        border-radius: 43px;
        background: rgba(48, 55, 70, 1);
    }

    &__textContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &__title {
        font-size: 17px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.40799999237060547px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }

    &__sub {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.07800000160932541px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }

    &__gradient {
        top: -90px;
        left: -100px;
        z-index: 2;
        position: absolute;
        width: 240px;
        height: 240px;

        &_green {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(0, 173, 60, 0.12) 0%,
                rgba(0, 237, 123, 0) 100%
            );
        }

        &_red {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(242, 27, 52, 0.13) 0%,
                rgba(240, 66, 72, 0) 100%
            );
        }
    }
}
