.Badge {
    display: flex;
    align-items: center;
    gap: 4px;

    width: fit-content;
    padding: 4px 8px 4px 8px;

    border-radius: 4px;
    cursor: pointer;
    transition: all ease 0.3s;

    small {
        transition: all ease 0.3s; 
    }

    &:hover small {
        color: rgba(253, 55, 7, 1);
    }
    
    &__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        color: rgba(168, 170, 183, 1);
    }

    &_withBackground {
        background-color: rgba(19, 19, 19, 1);

        small {
            color: rgba(248, 248, 248, 1);
        }

        &:hover {
            background-color: rgba(31, 31, 37, 1);
        }

        &:hover small {
            color: rgba(248, 248, 248, 1);
        }
    }
}