
.TextBlock {
    width: 100%;
    max-width: 416px;
}

.avatar {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    
    &__title {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color: #F8F8F8;
    }

    &__image {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
}

.messageBlock {
    margin-bottom: 24px;
    padding: 12px;
    box-shadow: 0px 4px 25px 0px #00000080;
    background-color: #393943;
    border-radius: 12px;
    color: #F8F8F8;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &__container {
        position: relative;
    }

    &__sendIconContainer {
        position: absolute;
        top: 0px;
        left: 0px;

        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__send {
        margin-left: auto;
        margin-right: 17px;
    }
}

.shadowGPTMessage {
    margin-bottom: 24px;
    padding: 12px;
    border-radius: 12px;
    background-color: #131313;
    box-shadow: 0px 0px 25px 0px #00000080;

    &__text {
        color: #F8F8F8;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0em;

        &_green {
            color: #00AC3B;
        }
    }

    &__textContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__divider {
        width: 100%;
        height: 1px;
        margin-top: 6px;
        margin-bottom: 8px;
        background-color: #393943;
    }

    &__checkCaption {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    * {
        text-align: left !important;
    }
}