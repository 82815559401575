.ButtonPrimary {
    position: relative;

    display: flex;
    gap: 15px;

    padding-left: 25px;
    padding-right: 25px;
    padding-top: 14px;
    padding-bottom: 11px;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;

    color: white;
    cursor: pointer;
    transition: 0.3s ease background-color;

}

.ButtonPrimary__basic-scheme {
    background-color: #131313;
}

.ButtonPrimary__basic-scheme:not(.ButtonPrimary__disabled):hover {
    background-color: rgba(31, 31, 37, 1);
}

.ButtonPrimary__orange-scheme {
    background-color: #FD3707;
}

.ButtonPrimary__orange-scheme:not(.ButtonPrimary__disabled):hover {
    background-color: rgba(255, 88, 48, 1);
}

.ButtonPrimary__dark-orange-scheme {
    border: #FD3707 1px solid;
    background-color: rgba(19, 19, 19, 1);
    color: #FD3707;


    &:hover {
        background-color: rgba(31, 31, 37, 1)
    }
}

.Mask {
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    background-color: rgba(0, 0, 0, 0.267);
    cursor: auto;
}