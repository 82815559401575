@import "../../../style/breakpoints.scss";

.StayInShadowBlock {
    position: relative;

    padding-top: 120px;
    padding-bottom: 120px;

    &__wrapper {
        z-index: 2;
        position: relative;
    }

    h2 {
        margin-bottom: 24px;
        color: #FF4A1E;
    }

    p {
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }

    &__textContainer {
        max-width: 738px;
        width: 100%;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        background-image: 
            url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='matrix' values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E"),
            linear-gradient(to bottom, #0a0a0a, #000000);
        background-blend-mode: soft-light, normal;
        background-repeat: repeat, no-repeat;
        background-size: 200px 200px, cover;
        opacity: 0.4;
    }

    &__flexContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
    }

    &__button {
        padding: 16px 32px 16px 32px
    }


    @media (max-width: $lg-min) {
        padding-top: 40px;
        padding-bottom: 40px;
        
        &__textContainer {
            max-width: none;
            margin-right: 0px;
            margin-bottom: 24px;
            text-align: left;
        }

        &__flexContainer {
            flex-direction: column;
            align-items: center;
            gap: 0px;
        }

        &__button {
            display: flex;
            justify-content: center;
            max-width: 360px;
            width: 100%;
        }
    }
}