@import "../../../../../style/mixins.scss";

.Request {

    &__textWrapper {
        display: flex;
        margin-bottom: 8px;
    }

    &__indicatorWrapper {
        display: flex;
        align-items: center;
        @include divider;
    }

    &__indicator {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        border-radius: 50%;
        background: rgba(0, 239, 215, 1);

        &_red {
            background-color: rgba(253, 55, 7, 1);
        }
    }

    &__time {
        @include divider;
    }

    &__textIcon {
        margin-bottom: 8px;
    }

    &__iconContainer {
        display: flex;
        gap: 5px;
    }

    &__icon {
        width: 16px;
        height: 16px;
    }

    &__textBlock {
        z-index: 2;
        position: relative;
        max-width: 416px;
        width: 100%;
        padding: 16px;
        margin-bottom: 8px;

        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        border-radius: 8px;
        background-color: rgba(7, 7, 7, 1);
    }
}