@import '../../style/breakpoints.scss';

.PositionWrapper {
    z-index: 9999;
    position: fixed;
    width: 100%;
    background-color: #070707;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    position: relative;

    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    &__gap {
        gap: 24px;
    }
    &__right {
        display: flex;
        align-items: center;
        p {
            color: rgba(248, 248, 248, 1);
            font-size: 12px;
            font-weight: 400;
            margin: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
        }
    }

    &__menuItem {
        cursor: pointer;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #f8f8f8;
        transition: 0.3s ease color;
        white-space: nowrap;

        &:hover {
            color: rgba(253, 55, 7, 1);
        }

        &_active {
            color: rgba(253, 55, 7, 1);
        }
    }

    @media (max-width: $lg-min) {
        display: none;
    }
}

.HeaderDropdown {
    cursor: pointer;
    position: relative;

    &__icon {
        transition: 0.3s ease all;
    }

    &__iconOpened {
        transform: rotate(180deg);
    }

    &__container {
        opacity: 0;
        z-index: -1;

        position: absolute;
        top: 50px;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        width: 189px;
        padding: 24px;
        border-radius: 8px;
        background-color: rgba(7, 7, 7, 1);
        transition: 0.3s ease all;
    }

    &__containerActive {
        opacity: 1;
        z-index: 9999;
    }
}

.buttonFlexWrapper {
    display: flex;
    gap: 24px;
}

.HeaderMobile {
    display: none;
    justify-content: space-between;
    align-items: center;

    padding-top: 19px;
    padding-bottom: 18px;

    &__burgerMobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        width: 18px;

        &_el {
            width: 100%;
        }
    }

    &__menuLogo {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
    }

    &__menuContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        width: 100%;
        height: 100%;

        padding-left: 24px;

        div {
            display: flex;
            justify-self: center;
            align-items: center;
            gap: 11px;
        }

        &_inactive {
            color: rgba(248, 248, 248, 1);
        }

        &_active {
            color: rgba(253, 55, 7, 1);
        }

        &_iconActive {
            fill: rgba(253, 55, 7, 1);
        }

        &_icon {
            width: 20px;
            height: 20px;
        }
    }

    &__ShadowChatButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    &__button {
        display: flex;
        align-items: center;
        gap: 4px !important;

        padding: 4px 8px 4px 8px !important;
        border-radius: 4px !important;

        small {
            font-size: 10px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    &_grayColor {
        color: rgba(168, 170, 183, 1);
    }

    &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transition: 0.3s all ease;

        &:hover {
            background-color: #070707be;
        }
    }

    @media (max-width: $lg-min) {
        display: flex;
    }
}

.ShadowChatMenu {
    border: 2px solid blue;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title {
        font-family: 'Inter';
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(248, 248, 248, 1);
        margin-bottom: 20px;
        position: absolute;
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__wrapper {
        display: grid;
        place-content: center;
        width: 100%;
        height: 100%;
    }
}

.AccountMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    padding-top: 32px;

    &__flexWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        width: 100%;
        padding-left: 24px;
    }

    &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 32px;
    }

    &__avatarTitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }
}
