@import "../../../../style/breakpoints.scss";

.AccountDetails {
  height: 100%;
  width: 100%;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-image: url('../../assets/background.svg'), linear-gradient(110deg, #000000 0%, #0A0A0A 43%, #120E0D 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center, center;
    background-size: cover, cover;
    background-blend-mode: overlay, normal;
  }

  &__wrapper {
    position: relative;
    z-index: 2;

    padding-left: 16px;
    padding-right: 16px;

    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: 40px;

    width: 100%;
    max-height: 100%;
  }

  &__flexWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    margin-left: auto;
    margin-right: auto;

    width: 100%;
    max-width: 600px;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__saveButton {
    display: flex;

    justify-content: center;

    width: fit-content;
    padding: 16px 32px 16px 32px;
    border-radius: 8px;

    p {
      color: rgba(248, 248, 248, 1);
    }
  }

  &__avatarContainer {
    display: flex;
    align-items: center;
    gap: 24px;

    margin-bottom: 24px;

    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }

  &__subAvatarFlexContainer {
    display: flex;
    justify-self: center;
    flex-direction: column;
    gap: 8px;

    small {
      text-align: center;
    }
  }

  &__getSuppDescription {
    text-align: left;
    margin-bottom: 24px;
  }

  &__bottomButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    &_btn {
      display: flex;
      align-items: center;
      gap: 16px;

      border: 2px solid rgba(168, 170, 183, 1);
      padding: 16px 32px 16px 32px;
      border-radius: 8px;
      cursor: pointer;

      p {
        color: white;
      }
    }

    &_deleteBtn {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      small {
        color: rgba(168, 170, 183, 1);
      }
    }
  }


  @media (max-width: $lg) {
    padding-top: 50px;
  }

}

.AccountInfoBlock {
  width: 100%;

  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
  background-color: rgba(255, 255, 255, 0.05);

  h5 {
    padding-bottom: 23px;
    margin-bottom: 23px;
    border-bottom: rgba(0, 0, 0, 1) 1px solid;

    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(248, 248, 248, 1);
  }
}