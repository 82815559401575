@import "../../../../../../style/breakpoints.scss";

.Prompt {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 248px;
    padding: 24px;

    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
    border-radius: 8px;
    background: #181818;

    &__title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(248, 248, 248, 1);
    }

    &__description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(248, 248, 248, 1);
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        font-size: 12px;

        padding: 8px;
    }

    @media (max-width: $lg) {
        &:last-child {
            display: none;
        }
    }
}