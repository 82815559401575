@import "../../style/breakpoints.scss";

.Footer {
    padding-top: 40px;
    padding-bottom: 42px;

    &__flexContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
        padding-bottom: 23px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(19, 19, 19, 1);
        position: relative;
    }

    &__sectionContainer {
        display: flex;
        gap: 24px;
    }

    @media (max-width: $md) {
        &__flexContainer {
            align-items: center;
            flex-direction: column;
        }

        &__sectionContainer {
            flex-direction: column;
            align-items: center;

            text-align: center;
        }
    }
}

.FooterSection {
    width: 100vw;
    max-width: 202px;

    * {
        text-align: left;
    }

    h4 {
        margin-bottom: 32px;

        font-size: 12px;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: 0.17em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
        text-transform: uppercase;
    }

    &__linksContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__linkContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    &__link {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
        transition: 0.3s ease all;

        &:hover {
            opacity: 0.8;
        }
    }

    @media (max-width: $md) {
        max-width: none;
        width: 100%;

        &__linksContainer {
            align-items: center;
            justify-content: center;
        }

        h4 {
            text-align: center;
        }

        &__link {
            color: rgba(168, 170, 183, 1);
        }
    }
}

.Copyright {
    display: flex;
    justify-content: space-between;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(168, 170, 183, 1);

    small, a {
        color: rgba(168, 170, 183, 1);
    }

    &__languageContainer {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__language {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(248, 248, 248, 1)
    }

    &__flexWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        gap: 24px;
        small { 
            cursor: pointer;
        }
    }

    @media (max-width: $md) {
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &__flexWrapper {
            flex-direction: column;
        }
    }
}