.SaleBadge {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 16px;
  overflow: hidden;
  isolation: isolate;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: -2px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.15), transparent);
    z-index: -1;
  }

  &::before {
    animation: shimmer 8s infinite linear;
  }

  &::after {
    animation: shimmer 8s infinite 4s linear;
  }

  &__text {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    z-index: 1;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}