@import "../../../style/mixins.scss";
@import "../../../style/breakpoints.scss";

.PastRequests {
    position: relative;
    overflow: hidden;

    &__noiseBackground {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__radialGradient1 {
        z-index: 3;
        position: absolute;
        top: -120%;
        left: -600px;
        width: 1200px;
        height: 1300px;
        border-radius: 50%;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,1) 7%, rgba(0,0,0,0.7063200280112045) 45%, rgba(0,0,0,0) 62%);
    }

    &__radialGradient2 {
        z-index: 3;
        position: absolute;
        bottom: -60%;
        right: -600px;
        width: 1300px;
        height: 1050px;
        border-radius: 50%;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,1) 7%, rgba(0,0,0,0.7063200280112045) 45%, rgba(0,0,0,0) 62%);
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background-image: url('./assets/Background.svg'), linear-gradient(130deg, #000000 0%, #161616 49%, #000000 100%);
        background-repeat: no-repeat, no-repeat;
        background-size: cover, 100% 100%;
        background-position: center, center;
        background-blend-mode: overlay, normal;
    }

    &__content {
        z-index: 4;
        position: relative;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__textContainer {
        max-width: 772px;
        @include centering;
    }
    
    h2 {
        margin-bottom: 23px;
        text-align: center;
    }

    p {
        margin-bottom: 40px;
        text-align: center;
    }

    &__requestContainer {
        display: flex;
        justify-content: center;
        gap: 25px;
    }

    @media (max-width: $lg-min) {
        &__noiseBackground {
            display: none;
        }

        &__requestContainer {
            flex-direction: column;
        }

        &__content {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__requestContainer {
            align-items: center;
            gap: 40px;
        }

        p {
            margin-bottom: 80px;
        }
    }
}