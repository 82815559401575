@import "../../style/breakpoints.scss";

.GenerationBlock {
    display: flex;

    width: 100%;
    height: 100dvh;

    &__page {
        width: 100%;
        height: 100%;
    }
}

.MobileHeader {
    display: none;

    @media (max-width: $lg-min) {
        display: block;
    }
}