@import "./mixins.scss";
@import "./breakpoints.scss";

.app-container {
    max-width: 1313px;
    @include centering;
}

@media(max-width: $lg) {
    .app-container {
        padding-left: 72px;
        padding-right: 72px;
    }
}

@media(max-width: $lg-min) {
    .app-container {
        padding-left: 16px;
        padding-right: 16px;
    }
}