@import '../../style/breakpoints.scss';
@import '../../style/breakpoints.scss';

.resizableTextarea {
    resize: none;
    min-height: 300px;
    max-height: 700px;
    overflow-y: auto;
}

.resizeHandle {
    display: none; // Hide by default
    position: absolute;
    left: 50%;
    bottom: -7px;
    width: 20px;
    height: 12px;
    background: rgba(100, 100, 100, 0.5);
    border-radius: 10px;
    cursor: ns-resize;
    z-index: 10;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;

    &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='rgba(200, 200, 200, 0.8)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='9' cy='12' r='1'%3E%3C/circle%3E%3Ccircle cx='9' cy='5' r='1'%3E%3C/circle%3E%3Ccircle cx='9' cy='19' r='1'%3E%3C/circle%3E%3Ccircle cx='15' cy='12' r='1'%3E%3C/circle%3E%3Ccircle cx='15' cy='5' r='1'%3E%3C/circle%3E%3Ccircle cx='15' cy='19' r='1'%3E%3C/circle%3E%3C/svg%3E");
        width: 12px;
        height: 19px;
        display: block;
        transform: rotate(90deg);
    }

    // Show on desktop screens
    @media (min-width: 1024px) {
        display: flex;
    }
}


.HumanizerInput {
    background: rgba(29, 29, 29, 0.96);
    border-radius: 20px;
    border: 1px solid rgba(19, 19, 19, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    transition: max-height 0.4s ease-in-out;

    &::-webkit-resizer {
        display: none;
    }

    &__proModelWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__proModelSelector {
        display: flex;
        align-items: center;
        align-self: self-start;
        gap: 10px;
        margin-right: 15px;

        span {
        font-size: 14px;
        color: #ffffff;
            }
    }

    &:hover {
      opacity: 1;
    }
    &__flexWrapper {
        display: flex;
        justify-content: space-between;
    }

    &__copyContainer {
        display: flex;
        justify-content: space-between;
    }

    &__rightBorder {
        border-right: 1px solid rgba(57, 57, 67, 1);
        height: 100%;
    }

    &__input {
        display: flex;
        flex-direction: column;
        width: 50%;

        padding-left: 26px;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
    }

    &__result {
        width: 50%;
        padding-left: 26px;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;

        &_text {
            opacity: 0;
            animation: fadeInOut 0.5s forwards alternate;

            width: 100%;
            height: 280px;
            font-family: 'Inter';
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(168, 170, 183, 1);
            text-align: left;
            background-color: transparent;
            margin-bottom: 17px;

            text-wrap: wrap;
        }
    }

    &__title {
        margin-bottom: 20px;

        color: rgba(248, 248, 248, 1);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
    }

    &__basicPlan {
        color: #f8f8f8;
    }

    &__greyColor {
        color: rgba(168, 170, 183, 1);
    }

    &__redColor {
        color: #ff0000;
    }

    &__fieldWrapper {
        position: relative;
        display: flex;

        margin-bottom: 17px;
        background-color: rgba(7, 7, 7, 1);
        border-radius: 8px;
    }

    &__field {
        position: relative;
        z-index: 2;

        width: 100%;
        height: 280px;
        padding: 16px;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: transparent;
        flex-grow: 1;

        font-family: 'Inter';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(168, 170, 183, 1);

        transition: 0.4s ease color;
    }

    &__field:focus {
    }

    @media (max-width: $lg-min) {
        &__flexWrapper {
            flex-direction: column;
        }

        &__aliginMod {
            align-items: flex-start;
            justify-content: flex-start;
        }

        &__rightBorder {
            border: none;
        }

        &__copyContainer {
            margin-bottom: 24px;
        }

        &__field {
            height: 316px;
        }

        &__input {
            width: 100%;
        }

        &__result {
            width: 100%;
        }
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.buttons_2 {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
}

.leftContent {
    display: flex;
    align-items: center;
}

.rightContent {
    display: flex;
    gap: 16px;
}

.HumanizerInput__proModelSelector {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: $lg-min) {
    .buttons {
        flex-direction: column-reverse;
        padding-bottom: 23px;
        border-bottom: 1px solid rgba(57, 57, 67, 1);
    }
    
    .buttons_2 {
        flex-direction: column-reverse;
        padding-bottom: 23px;
        border-bottom: 1px solid rgba(57, 57, 67, 1);
    }

    .leftContent {
        width: 100%;
        justify-content: center;
        margin-top: 16px;
    }

    .rightContent {
        width: 100%;
        flex-direction: column;
    }

    .buttons__centeringMod {
        padding: 8px 16px 8px 16px;
        justify-content: center;
    }

    .HumanizerInput__proModelSelector {
        justify-content: center;
    }
}

.copyIcon {
    cursor: pointer;

    &__caption {
        margin-right: 7px;
    }
}

.ActionWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 34px;
        height: 100%;
    }

    @media (max-width: $lg-min) {
        height: calc(100% - 30px);

        &__container {
            gap: 24px;
            justify-content: flex-end;
            flex-direction: column;
        }
    }
}

.Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    opacity: 0;
    animation: fadeInOut 0.5s forwards alternate;

    &__bar {
        position: relative;
        overflow: hidden;

        width: 100%;
        border-radius: 8px;
        height: 32px;
        background-color: black;
    }

    &__barAnimated {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.36458333333333337) 0%,
            rgba(38, 38, 38, 1) 50%,
            rgba(38, 38, 38, 0.39539565826330536) 100%
        );

        animation: slide 2s linear infinite;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
