@import '../../../../style/breakpoints.scss';

.ShadowGPT {
    display: flex;
    height: 100dvh;

    &__sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
        padding: 80px 24px 40px 24px;
    }

    &__tariffContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        margin-top: auto;

        small {
            color: rgba(248, 248, 248, 1);
        }

        &_indicatorContainer {
        }

        &_indicator {
            position: relative;
            width: 100%;
            height: 6px;
            background-color: rgba(248, 248, 248, 1);
            border-radius: 10px;
        }
    }

    &__upgrade {
        display: flex;
        justify-content: center;
        gap: 16px;

        padding: 8px;
        border-radius: 8px;
        background-color: rgba(253, 137, 7, 1);
        color: rgba(248, 248, 248, 1);
        transition: 0.3s ease all;
        cursor: pointer;

        &:hover {
            background-color: #ff5830;
        }
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100%;

        &_content {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
        }

        &_noiseBackground {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    
            background-image: url('../../assets/background.svg'), linear-gradient(110deg, #000000 0%, #0A0A0A 49%, #120E0D 100%);
            background-repeat: no-repeat, no-repeat;
            background-size: cover, 100% 100%;
            background-position: center, center;
            background-blend-mode: overlay, normal;
        }
        @media screen and (max-width: 768px) {
            height: 100dvh;
            overflow-y: hidden;
            &_noiseBackground { 
                display: none;
            }
        }
    }

    &__gradientMask {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 39%,
            rgba(0, 0, 0, 0.4990371148459384) 60%,
            rgba(0, 0, 0, 0) 94%
        );
    }

    &__backgroundElement {
        z-index: 1;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    @media (max-width: $lg-min) {
        &__sidebar {
            display: none;
        }
    }
}

.ShadowGPTContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 10;

    h2 {
        margin-bottom: 40px;
    }

    &__titleSpan {
        color: rgba(253, 55, 7, 1);
    }

    &__flexWrapper {
        display: flex;
        justify-content: center;
        gap: 24px;
    }

    @media (max-width: $md) {
        &__flexWrapper {
            display: none;
        }

        h2 {
            display: none;
        }
    }
}

.ChatList {
    width: 100%;
    height: 100vh;
    max-height: 100%;

    &__inner {
        max-width: 752px;
        width: 100%;
        height: 100%;
        padding-top: 50px;
        padding-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        max-height: 94%;
        overflow-y: auto;

        @media screen and (max-width: 768px) {
            padding: 81px 16px 16px;
            height: 86dvh;
            position: relative;
            max-height: 90dvh;
        }
    }

    &__inner::-webkit-scrollbar {
        display: none;
    }

    &__userMsg {
    }

    &__userMsg p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    @media screen and (max-width: 768px) {
        height: 100dvh;
        max-height: 100dvh;
        overflow-y: hidden;
    }
}

.BotMessage {
    padding: 24px;
    gap: 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
    background: rgba(255, 255, 255, 0.05);

    &__nickContainer {
        margin-bottom: 24px;
    }
}

.ChatMessage {
}
