.ShadowChatList {
    &__dots {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        opacity: 0;
        height: 30px;
        width: 30px;
        span {
            width: 1.5px;
            height: 1.5px;
            border-radius: 50%;
            background: #fff;
        }
    }
    &_dropdown {
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 0;
        background: #131313;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 24px;
        border-radius: 8px;
        display: flex;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s all ease;
        transform: translateY(-5px) translateX(0);
        &_show {
            user-select: none;
            visibility: visible;
            opacity: 1;
            transform: translateY(0) translateX(0);
        }
        &_item {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 4px;
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 15.6px;
            }
        }
        @media screen and (max-width: 768px) {
            position: relative;
            top: 0;
            * {
                transform: translateY(0) translateX(0);
            }
        }
    }
    &__chatButtonContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 192px;
        max-height: 100%;
        height: 100%;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            max-height: 60dvh;
            margin-top: auto;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 32px 1fr;
        }
    }
    &__chats {
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        grid-template-rows: 32px;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        scroll-margin: 50px;
        &::-webkit-scrollbar {
            width: 0;
        }
        @media screen and (max-width: 768px) {
            height: 280px;
        }
    }

    &__chatButton {
        padding: 0 16px 0 16px;
        border-radius: 8px;
        cursor: pointer;
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(248, 248, 248, 1);
        transition: 0.3s ease all;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
            border-color: rgba(253, 55, 7, 1);
            color: rgba(253, 55, 7, 1);
        }

        input {
            background: none;
            border: none;
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
        }

        &_selected {
            background-color: rgba(19, 19, 19, 1);
            border-color: transparent !important;
            color: rgba(248, 248, 248, 1) !important;
            cursor: initial;
        }

        &_addNew {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: rgba(253, 55, 7, 1);

            border-color: transparent !important;
            color: rgba(248, 248, 248, 1) !important;
        }
        @media screen and (max-width: 768px) {
            width: 100%;    
            height: 32px;
        }
    }

    @media screen and (max-width: 768px) {
        &__chatButtonContainer {
        }
    }
}
