@import "../../style/breakpoints.scss";

.ErrorPage {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    width: 100vw;
    height: 100vh;
    
    & * {
        position: relative;
        z-index: 2;
    }

    &__background {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: url("./assets/Back.webp");
        background-repeat: no-repeat;
        background-size: 1920px 100%;
    }

    &__title {

        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(248, 248, 248, 1);
    }

    h1 {
        // margin-bottom: 20px;
        color: rgba(248, 248, 248, 1);
    }

    &__button {
        z-index: 2;
        position: relative;
        padding: 16px 32px 16px 32px;
    }

    &__footer {
        display: none;
    }

    @media (max-width: $lg-min) {
        &__footer {
            display: block;
        }

        &__title {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.02em;
            text-align: center;
        }

        &__background {
            // width: 50%;
            // height: 50%;
            // background-position: 0% 0%;
            background-size: cover;
            // background-size: 1000px 1000px;
            // background-size: ;
        }
    }
}