@import '../../../../style/breakpoints.scss';

.Billings {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    margin: 0 auto;

    &__buttonContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        margin-top: 16px;
      }
      
    &__title {
        height: auto;
        font-size: 12px;
        position: absolute;
        font-weight: 400;
        color: #fff;
        z-index: 1;
        top: 30px;
        left: 24px;
        span {
            height: 16px;
            padding: 0 4px;
            margin-left: 8px;
            background: rgba(253, 55, 7, 0.2);
            color: #fd3707;
            border-radius: 4px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }       
    }
    &__noiseBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        background-image: url('../../assets/background.svg'), linear-gradient(110deg, #000000 0%, #0A0A0A 43%, #120E0D 100%);
        background-repeat: no-repeat, no-repeat;
        background-position: center, center;
        background-size: cover, cover;
        background-blend-mode: overlay, normal;
    }

    &__gradientMask {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 39%,
            rgba(0, 0, 0, 0.4990371148459384) 60%,
            rgba(0, 0, 0, 0) 94%
        );
    }

    &__backgroundElement {
        z-index: 1;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    p {
        color: rgba(248, 248, 248, 1);
    }

    &__wrapper {
        z-index: 20;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        height: 100%;
        max-height: 100%;
        padding-top: 40px;
        padding-bottom: 40px;

        overflow-y: auto;
    }

    &__changePlanBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        cursor: pointer;
        width: fit-content;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
        background-color: rgba(255, 74, 30, 1);

        svg {
            fill: rgba(248, 248, 248, 1);
        }
    }

    &__buttonContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        margin-top: 16px;
    }

    &__cancelSubscriptionBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        cursor: pointer;
        width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: transparent;
        box-shadow: inset 0 0 0 1px rgba(248, 248, 248, 1);
        color: rgba(248, 248, 248, 1);

        &:hover {
            background-color: rgba(248, 248, 248, 0.1);
        }
    }

    &__changePaymentBtn {
        border-radius: 4px;
        background-color: rgba(19, 19, 19, 1);
    }

    &__masterCard {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &__flexWrapper {
        display: flex;
        justify-content: center;
        gap: 24px;

        width: 100%;
        margin-bottom: 24px;
    }

    &__transactionTable {
        width: 100%;
        max-width: 800px;
        height: fit-content;
        // max-height: 520px;

        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.1) inset;
    }

    &__tableRow {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background-color: #191919;
        align-items: center;
        &:nth-child(odd) {
            background-color: #131313;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        p {
            text-align: left;
        }

        @media screen and (max-width: 380px) {
            grid-template-columns: repeat(3, 109px);
        }
        @media screen and (max-width: 380px) {
            p {
                font-size: 13px;
            }
        }
    }

    &__tableItem {
        display: flex;
        align-items: flex-start;

        padding: 16px;
        @media screen and (max-width: 768px) {
            &:nth-child(2) {
                padding: 16px 0 16px 16px;
            }
        }
        @media screen and (max-width: 380px) {
            &:last-child {
                padding-left: 0;
            }
        }
    }

    &__indicatorContainer {
        display: flex;
        align-items: center;
        gap: 8px;

        padding: 16px;
        width: 267px;
        p::first-letter {
            text-transform: uppercase;
        }
        @media screen and (max-width: 768px) {
            width: auto;
            p {
                overflow: hidden;
                word-break: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }
        @media screen and (max-width: 380px) {
            padding-left: 0;
        }
    }

    &__indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &_COMPLETED {
            background-color: rgba(0, 172, 59, 1);
        }

        &_PROCESSING {
            background-color: rgba(253, 137, 7, 1);
        }

        &_FAILED {
            background-color: rgba(242, 26, 52, 1);
        }
    }

    @media (max-width: $lg-min) {
        padding-top: 70px;

        &__tableItem:first-child,
        &__tableItem:nth-child(2) {
            padding-right: 0;
        }

        &__wrapper {
            padding-left: 16px;
            padding-right: 16px;
            margin-left: 0px;
        }

        &__flexWrapper {
            display: grid;
            grid-template-columns: 1fr;
        }

        &__transactionTable {
            width: 100%;
            max-width: 800px;
        }
    }
}

.Navigation {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    &__button {
        display: flex;
        justify-content: center;
        justify-content: center;

        width: 24px;
        height: 24px;
        padding: 4px 8px 4px 8px;
        border: 1px solid rgba(168, 170, 183, 1);
        border-radius: 4px;
        background-color: rgba(19, 19, 19, 1);
        transition: 0.3s all ease;
        cursor: pointer;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(248, 248, 248, 1);

        &_selected {
            border: 1px solid rgba(248, 248, 248, 1);
        }

        &:hover {
            background-color: rgba(31, 31, 37, 1);
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 24px;
    }
}
