@import "../../../../../style/breakpoints.scss";

.ReviewBlock {
    position: relative;
    z-index: 999;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 24px;
    border-radius: 20px;
    background-color: rgba(19, 19, 19, 1);
    width: 100%;
    max-width: 342px;
    height: 178px;

    small {
        position: relative;
        z-index: 2;
    }

    p {
        z-index: 2;
        font-size: 14px;
        text-align: left;
        color: rgba(248, 248, 248, 1);
    }

    &__avatarContainer {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &__avatar {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        object-fit: cover;
    }

    &__quotesTop {
        position: absolute;
        top: 4px;
        left: 4px;
    }

    &__quotesBottom {
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    @media (max-width: $lg-min) {
        max-width: 328px;
    }
}