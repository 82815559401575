.BurgerMenu {
    position: relative;
    top: -64px;
    z-index: -10;

    &_opened {
        z-index: 999;
    }

    &__contentWrapper {
        position: relative;
        z-index: 10;
    }

    &__menu {
        z-index: -10;
        position: absolute;
        top: 2px;
        left: 0px;
        width: 100%;
        max-width: 240px;
        height: 100dvh;
        padding-bottom: 25px;

        background-color: rgba(7, 7, 7, 1);

        transform: translateX(calc(-100% - 74px));
        transition: 150ms ease-in-out transform;

        &_rightMenu {
            left: initial;
            right: 0;
            transform: translateX(calc(150% + 240px + 77px));
        }

        &_opened {
            z-index: 10;
            transform: translateX(0);
        }
    }

    &__menuCloseBtn {
        z-index: 9999;
        position: absolute;
        top: 45%;
        right: calc(-30%);

        display: flex;
        justify-content: center;
        align-items: center;

        width: 64px;
        height: 64px;
        border-radius: 50%;

        background-color: rgba(7, 7, 7, 1);

        &_rightMenu {
            right: calc(240px + 5px);
        }
    }
}

.Mask {
    position: fixed;
    z-index: -998;
    top: 0;
    left: 0;
    display: none;

    width: 100vw;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    opacity: 0;

    transition: 0.3s all ease;
    background-color: rgba(7, 7, 7, 0.501);

    &__opened {
        display: block;
        opacity: 1;
        z-index: 998;
    }
}