.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease, visibility 0.3s ease;
  }
  
  .overlay.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .guaranteeText {
    font-size: 0.75rem;
    color: #666;
    text-align: center;
    margin-top: 0.25rem;
  }
  
  .subscriptionToastContainer {
    position: relative;
    z-index: 9999;
    max-width: 100%;
    background: linear-gradient(135deg, #2a2a2a, #3a3a3a);    
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }
  
  .subscriptionToast {
    text-align: center;
    color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    width: 100%;
    z-index: 9999;
  
    h3 {
      font-size: 1.5em;
      margin-bottom: 20px;
      color: #fd3707;
    }
  
    .toastText {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 20px;
      color: #e0e0e0;
    }
  
    .priceHighlight {
      font-size: 24px;
      font-weight: bold;
      color: #fd3707;
      display: inline-block;
    }
  
    button {
      background-color: #fd3707;
      color: white;
      border: none;
      padding: 10px 25px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      transition: all 0.3s ease;
      text-transform: uppercase;
  
      &:hover {
        background-color: #e13006;
        transform: translateY(-2px);
        box-shadow: 0 4px 10px rgba(253, 55, 7, 0.3);
      }
    }
  }
  
  .upgradeButton {
    @extend button;
    margin-top: 15px;
  }