@import "../../../../style/breakpoints.scss";

.SideMenu {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: fit-content;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    background-color: rgba(7, 7, 7, 1);
    border-right: 1px solid rgba(7, 7, 7, 1);

    &__title {
        position: absolute;
        top: 40px;
        left: calc(100% + 24px);

        display: flex;
        gap: 8px;

        small {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(248, 248, 248, 1);
        }

        div {
            padding: 0px 4px 0px 4px;
            border-radius: 4px;
            background-color: rgba(253, 55, 7, 0.2);

            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(253, 55, 7, 1);

        }
    }

    &__logo {
        margin-left: 40px;
        margin-right: 40px;
    }

    &__mainFlexWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        flex-grow: 10;
    }

    &__flexWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
    }

    &__menuItem {
        width: 24px;
        height: 24px;
        fill: #F8F8F8;
        cursor: pointer;
        transition: 0.3s ease opacity;

        &_active {
            fill: #FD3707;
        }
    }

    &__menuItem:hover {
        opacity: 0.7;
    }

    &__avatarContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    &__avatarWrapper {
        position: relative;
        width: 32px;
        height: 32px;
    }

    &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    &__avatarHoverContainer {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease all;

        cursor: pointer;

        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.684);
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    @media (max-width: $lg-min) {
        display: none;
    }
}